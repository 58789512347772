var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',{scopedSlots:_vm._u([{key:"left-tools",fn:function(){return [_c('timeframe-select')]},proxy:true},{key:"right-tools",fn:function(){return [_c('v-btn',{domProps:{"textContent":_vm._s('Create')},on:{"click":function($event){return _vm.$router.push({name: 'admin.market-reports.create'})}}})]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.marketReports,"items-per-page":5},on:{"click:row":_vm.handleClickRow},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formattedDate")(item.created_at))+" ")]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag){return _c('v-chip',{key:"tag-"+tag.id,attrs:{"x-small":""},domProps:{"textContent":_vm._s(tag.name)}})})}},{key:"item.lang",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":""},domProps:{"textContent":_vm._s(item.language)}})]}},{key:"item.downloads_count",fn:function(ref){
var item = ref.item;
return [_vm._l((item.downloads_count),function(size,entity){return [_c('v-chip',{key:entity,attrs:{"x-small":""}},[_vm._v(_vm._s(entity)+": "+_vm._s(size))])]})]}},{key:"item.file_size",fn:function(ref){
var item = ref.item;
return [_vm._l((item.file_size),function(size,entity){return [_c('v-chip',{key:entity,attrs:{"x-small":""}},[_vm._v(_vm._s(entity)+": "+_vm._s(size))])]})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('delete-dialog',{attrs:{"model":item,"type":"market-reports"},on:{"success":_vm.init}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }